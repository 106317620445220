import React from "react"
import GoogleRecaptcha from "react-google-recaptcha"

const Recaptcha = ({
  recaptchaRef,
  onChange,
}: {
  recaptchaRef: React.MutableRefObject<any>
  onChange: () => void
}) => {
  return (
    <GoogleRecaptcha
      ref={recaptchaRef}
      sitekey={process.env.GATSBY_GOOGLE_RECAPTCHA_KEY}
      size="normal"
      id="recaptcha-google"
      onChange={onChange}
    />
  )
}

export default Recaptcha
