import React from "react"
import ContactForm from "../components/contact/ContactForm"
import HeadlineM from "../components/HeadlineM"
import Layout from "../components/Layout"
import Seo from "../components/Seo"

const contact = () => {
  return (
    <Layout>
      <Seo title="お問い合わせ" />
      <div className="mt-16">
        <HeadlineM title="お問い合わせ" />
        <ContactForm />
      </div>
    </Layout>
  )
}

export default contact
