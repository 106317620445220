import React, { useEffect, useRef, useState } from "react"
import { useForm } from "react-hook-form"
import ReactModal from "react-modal"
import { useLocation } from "@reach/router"
import { navigate } from "gatsby"
import Recaptcha from "../Recaptcha"

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const ContactForm = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [recaptchaStatus, setRecaptchaStatus] = useState(false)
  const location = useLocation()
  const recaptchaRef = useRef<any>()

  const recaptchaSuccess = () => {
    setRecaptchaStatus(true)
  }

  useEffect(() => {
    if (location.search.includes("success")) {
      setIsModalOpen(true)
    }
  }, [location])

  const onSubmit = (data, e) => {
    const recaptchaValue =
      recaptchaRef.current && recaptchaRef.current.getValue()
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "contact",
        "g-recaptcha-response": recaptchaValue,
        ...data,
      }),
    })
      .then(() => navigate(e.target.getAttribute("action")))
      .catch(error => alert(error))
  }

  const closeModal = () => {
    setIsModalOpen(false)
    navigate(location.pathname)
  }

  return (
    <div className="max-w-2xl mx-auto mt-8">
      <form
        className="mt-8"
        name="contact"
        method="post"
        data-netlify="true"
        netlify-honeypot="bot-field"
        data-netlify-recaptcha="true"
        action="/contact/?success"
        onSubmit={handleSubmit(onSubmit)}
      >
        <input type="hidden" name="form-name" value="contact" />
        <label>
          <div>
            お名前<span className="ml-2 text-red-400">*</span>
          </div>
          <input
            {...register("name", { required: true })}
            type="text"
            name="name"
            className="w-full p-2 mt-2 border rounded border-Yellow"
          />
          {errors.name && (
            <div className="mt-1 text-sm text-red-400">
              項目を入力してください
            </div>
          )}
        </label>
        <label>
          <div className="mt-5">
            ふりがな<span className="ml-2 text-red-400">*</span>
          </div>
          <input
            {...register("furigana", { required: true })}
            type="text"
            name="furigana"
            className="w-full p-2 mt-2 border rounded border-Yellow"
          />
          {errors.furigana && (
            <div className="mt-1 text-sm text-red-400">
              項目を入力してください
            </div>
          )}
        </label>
        <label>
          <div className="mt-5">
            メールアドレス<span className="ml-2 text-red-400">*</span>
          </div>
          <input
            {...register("email", { required: true })}
            type="email"
            name="email"
            className="w-full p-2 mt-2 border rounded border-Yellow"
          />
          {errors.email && (
            <div className="mt-1 text-sm text-red-400">
              項目を入力してください
            </div>
          )}
        </label>
        <label>
          <div className="mt-5">
            メッセージ<span className="ml-2 text-red-400">*</span>
          </div>
          <textarea
            {...register("message", { required: true, maxLength: 1000 })}
            name="message"
            rows={8}
            className="w-full p-2 mt-2 border rounded border-Yellow"
          />
          {errors.message?.type === "required" && (
            <div className="mt-1 text-sm text-red-400">
              項目を入力してください
            </div>
          )}
          {errors.message?.type === "maxLength" && (
            <div className="mt-1 text-sm text-red-400">
              1000文字以内で入力してください
            </div>
          )}
        </label>
        <div className="mx-auto mb-10 mt-7">
          <Recaptcha recaptchaRef={recaptchaRef} onChange={recaptchaSuccess} />
        </div>
        <button
          type="submit"
          disabled={!recaptchaStatus}
          className={`block w-full md:w-48 px-5 py-3 mx-auto mt-5 text-sm font-semibold rounded bg-Yellow text-DeepGreen ${
            !recaptchaStatus ? "opacity-50" : ""
          }`}
        >
          送信
        </button>
      </form>
      <ReactModal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        ariaHideApp={false}
        className="fixed w-4/5 px-5 py-8 text-center transform -translate-x-1/2 -translate-y-1/2 bg-white rounded shadow top-1/2 left-1/2 md:w-96"
        overlayClassName="fixed z-10 top-0 left-0 right-0 bottom-0 bg-opacity-30 bg-black"
      >
        <h2>
          <span className="hidden md:block">お問い合わせ</span>
          ありがとうございます！
        </h2>
        <div className="mt-4">
          内容を確認して数日以内にご連絡差し上げます。
          <br />
          しばらくお待ちください。
        </div>
        <button
          onClick={closeModal}
          className="block w-32 py-2 mx-auto mt-8 rounded text-DeepGreen bg-Yellow hover:opacity-80"
        >
          OK
        </button>
      </ReactModal>
    </div>
  )
}

export default ContactForm
